import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          language: 'en',
          heading: 'Har Ghar Tiranga',
          sub_heading: 'Lets unite through a Tiranga',
          pin_a_flag: 'PIN A FLAG',
          know_more: 'Know more',
          video_title: 'Har Ghar Tiranga',
          login: 'Login',
          blog: 'Blog',
          buy_flag: 'Buy Flag',
          gift_flag: 'Gift a Flag',
          video: 'videos/2.mp4',
          video_text:
            'The Tri-colour flag is a symbol of pride for every Indian. It represents national integrity and signifies the hopes and aspirations of the Indian people. ',
          watch_more: 'images/more_videos.png',

          hashtag: '#harghartiranga',
          text:
            'The Tri-colour flag is a symbol of pride for every Indian. It represents national integrity and signifies the hopes and aspirations of the Indian people.',

          images: [
            'images/1.jpg',
            'images/2.jpg',
            'images/3.jpg',
            'images/4.jpg',
            'images/5.jpg',
            'images/6.jpg',
            'images/7.jpg',
          ],

          about_the_flag: 'About',
          about_the_flag1:
            "The Indian National Flag is a symbol of national pride for the entire nation. To further honor our flag, the Hon'ble Home Minister who oversees all efforts under Azadi Ka Amrit Mahotsav has approved the program of ‘Har Ghar Tiranga’. It envisages inspiring Indians everywhere to hoist the national flag at their home. ",
          about_the_flag2:
            ' Our relationship with the flag has always been more formal and institutional than personal. Bringing the flag home collectively as a nation in the 75th year of independence thus becomes symbolic of not only an act of personal connection to the Tiranga but also an embodiment of our commitment to nation-building, The idea behind the initiative is to invoke the feeling of patriotism in the hearts of the people and promote awareness about our national flag.',
          // about_the_flag3:
          //   '"Har-Ghar-Tiranga" is an ambitious concept that encourages all citizens to unfurl the national flag at their homes. The national flag hoisted at every home in the country represents the strength and unity of one nation. It heightens our feelings of patriotism and boosts our nationalist spirits.',
          flag_title: 'About',
          upload_selfie_button_text: 'Upload Selfie',
          upload_selfie_title: 'Upload Selfie',
          upload_selfie_text:
            '',

          flag_text:
            "The Indian National Flag is a symbol of national pride for the entire nation. To further honor our flag, the Hon'ble Home Minister who oversees all efforts under Azadi Ka Amrit Mahotsav has approved the program of ‘Har Ghar Tiranga’.",
          globe_text: 'FLAGS PINNED SO FAR',
          back_button_text: 'BACK',
          flag_suppliers: 'Flag Suppliers',
        },
      },
    },
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
