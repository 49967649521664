import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import paths from '../paths';

const PrivateRoute = ({ path, component: Component }) => {
  const { id } = useSelector(
    (state) => ({
      id: state.auth.userData.id,
    }),
    shallowEqual
  );
  console.log(path);
  let val;
  if (path === '/') {
    val = <Component />;
  } else {
    val = id ? <Component /> : <Redirect to={paths.ROOT} />;
    // val = id && <Component />;
  }
  return (
    <Layout>
      <Route exact path={path} render={() => val} />
    </Layout>
  );
};

PrivateRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
};

export default PrivateRoute;
