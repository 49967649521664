import React from 'react';
import Certificate from '../Certificate/Certificate';
import './PopupModal.css';

function Modal({
  setOpenModal,
  image,
  name,
  setShowFlagCertificate,
  showFlagCertificate,
}) {
  // const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpenModal(false);
    window.open('https://shop.harghartiranga.com', '_blank');
  };

  return (
    <>
      <div className="modalBackground">
        <div className="modalContainer" style={{ width: 'min-content' }}>
        <div className="float-right" onClick={()=>{setOpenModal(false);}} style={{ right: 30,position: 'absolute' }}>
              X
              </div>
          <div className="popup-header">
            
            <div className="imagediv">
              <img src={image} className="popup-image" alt="user" />
            </div>
            {/* <div> <h2 className="popup-text">{name}</h2>
             <div className="popup-subtext">
               <p>State: Haryana</p>
               <p>District: Gurugram</p>
             </div>
             </div> */}
          </div>
          <div>
            <div className="popupText">
              <span className="text-orange"> Congratulations!</span> Your flag
              has been pinned.
            </div>
            {/* <div className="subText">Hey {name}! Do you want to</div> */}
          </div>
          {/* <div className="buy-flag-div d-flex justify-content-center">
          <button
            className="buy-flag"
            onClick={() => {
              handleClick();
            }}
          >
            Buy flag
          </button>
        </div> */}
          <div
            className="skip d-flex justify-content-center"
            onClick={() => {
              setOpenModal(false);

              setShowFlagCertificate(true);
            }}
          >
            <p>Download Certificate</p>
          </div>
        </div>
      </div>
      {showFlagCertificate && (
        <Certificate
          name={name}
          image={image}
          setShowFlagCertificate={setShowFlagCertificate}
        />
      )}
    </>
  );
}

export default Modal;
