import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import PopupModal from './PopupModal';
import data from '../../assets/data.json';
import firebase from '../../firebase';

function uploadSelfie({ setOpenModal, show }) {
  const { t, i18n } = useTranslation();
  const [images, setImages] = React.useState([]);
  const [screenSize, setScreenSize] = React.useState('');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  React.useEffect(() => {
    setScreenSize(window.innerWidth);
    const ref = firebase.firestore();

    // Create a query against the collection

    const db = ref.collection('tiranga');

    db
      // .where('moderated', '==', true)

      .orderBy('moderated', 'desc')
      .limit(9)
      .get()
      .then((snapshot) => {
        const imageList = [];
        snapshot.forEach((value) => {
          imageList.push(value.data().image_path);
        });
        setImages(imageList);
        //  console.log(imageList);
      });
  }, [show]);

  return (
    <>
      <div>
        <Container>
          <Row className="mt-3">
            <Col lg={6} sm={12}>
              <h3 className="flag_title pt-lg-5 mb-4 topic-title">
                {t('upload_selfie_title')}
              </h3>
              <p className="flag_text topic-desc">{t('upload_selfie_text')}</p>
              <div className="row">
                <div
                  className="about_btn col-lg-12 col-md-12"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  <div
                    className=" row mt-5 mb-3"
                    style={{
                      backgroundColor: '#F97428',
                      height: '57px',
                      width: '250px',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    <div className="col-8 pt-2 ">
                      <p className="btn_text mt-2 ">
                        {t('upload_selfie_button_text')}
                      </p>
                    </div>
                    <div className="col-4 pt-2">
                      <img
                        className="img-fluid"
                        src="/images/arrow_btn.svg"
                        width="40px"
                        height="40px"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className=" col-lg-12 col-md-12 read-more">
                  <a href="/selfi">
                    <h4 className="readMoreText">More selfies</h4>
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <Container className="selfie-cont">
                <Row
                  style={{ display: 'contents' }}
                // classsName={screenSize < 970 ? 'selfi-row' : 'self-row'}
                >
                  {(images.length >= 0
                    ? images
                    : t('images', {
                      returnObjects: true,
                    })
                  ).map((item, idx) => (
                    <Col
                      lg={4}
                      className="hashtagImg"
                      style={{ display: 'inline-block' }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${item})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          // textAlign: 'center',
                          backgroundRepeat: 'no-repeat',
                          // overflowY: 'scroll',
                          padding: 'none',
                          overflowX: 'hidden',
                          height: '150px',
                          color: '#C1A56E',
                          borderRadius: '5px',
                        }}
                        src={item}
                        alt="img"
                        onClick={() => {
                          console.log('helloooooooooo');
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default uploadSelfie;
