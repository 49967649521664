import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import Home from 'pages/Home';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import Section from 'pages/Section';
import Submenu from 'pages/Submenu';
import paths from './paths';
import PrivateRoute from './PrivateRoute';
import blog from '../Blog';
import signup from '../Signup';
import topic from '../Topic';
import Selfi from '../Selfi/Selfi';
import FlagSuppliers from '../FlagSuppliers';


const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={paths.BLOG} component={blog} />
        <Route path={paths.BLOG_TOPIC} component={topic} />
        <Route exact path={paths.SELFI} component={Selfi} />
        <Route exact path={paths.FLAG_SUPPLIERS} component={FlagSuppliers} />
        <PrivateRoute exact path={paths.SIGNUP} component={signup} />
        <PrivateRoute path={paths.ADD_USER} component={User} />
        <PrivateRoute path={paths.MODIFY_USER} component={User} />
        <PrivateRoute path={paths.USERS} component={Users} />
        <PrivateRoute path={paths.PROFILE} component={Profile} />
        <PrivateRoute path={paths.SUBMENU_1} component={Submenu} />
        <PrivateRoute path={paths.SUBMENU_2} component={Submenu} />
        <PrivateRoute path={paths.LANDING} component={Home} />
        <PrivateRoute path={paths.ROOT} component={Section} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
