import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
// import Lottie from 'react-lottie';
// import animation from './globe.json';
import firebase from '../../firebase';
// import pin from './pin_btn.svg';

import data from '../../assets/data.json';

function globe1({ onHide }) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animation,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };
  const [clicks, setClicks] = React.useState();

  const defdatabase = firebase.firestore().collection('pin_count');
  React.useEffect(() => {
    defdatabase.doc("XJWs5wYY6IuCyeWudzLI").get().then((snapshot) => {
      // const pinvals = [];
      // snapshot.forEach((doc) => {
      //   pinvals.push(doc.data());
      // });
      setClicks(snapshot.data().count);
    }
    );

  }, []);

  return (
    <div>
      {/* <Container>
        <Col> */}
      <h1 className="header topic-title">{t('heading')}</h1>
      <p className="sub_header ">{t('sub_heading')}</p>
      <Link className="about_btn" to="/login">
        <div
          className="flag_btn row mt-5 mb-3"
          style={{
            backgroundImage: `url(${'/images/org_btn.svg'})`,

            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '200px',
            borderRadius: '10px',
            display: 'inline-block',
          }}
        >
          <div className="m-2" onClick={onHide}>
            <p className="btn_text">{t('pin_a_flag')}</p>
          </div>
        </div>
        {/* <img className="pin_btn" src={data.language[0].pin_btn} alt="pin_btn" /> */}
      </Link>
      <Row className="justify-content-md-center pt-3">
        <Col>
          {/* <Lottie options={defaultOptions} /> */}
          <h3 className="total_flag">{clicks}</h3>
          <p className="globe_text text-nowrap pt-2">
            {data.language[0].globe_text}
          </p>
        </Col>
      </Row>
      {/* </Col>
      </Container> */}
    </div>
  );
}

export default globe1;
