import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/analytics';
// import 'firebase/app-check';

// const config = {
//   apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
//   authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIRE_BASE_DB_URL,
//   projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID,
// };

const config = {
  apiKey: "AIzaSyAxmzkGOh99FDueODxTgNNoQlfmtX46qzA",
  authDomain: "harhathjhanda.firebaseapp.com",
  projectId: "harhathjhanda",
  storageBucket: "harhathjhanda.appspot.com",
  messagingSenderId: "878567490070",
  appId: "1:878567490070:web:dfed74aadf80a3f263eb37",
  measurementId: "G-13ZCMH0CVW",
  databaseURL: "https://harhathjhanda-default-rtdb.asia-southeast1.firebasedatabase.app",

};

const app = firebase.initializeApp(config);
firebase.database();
firebase.storage();
firebase.firestore();
firebase.analytics.isSupported().then((isSupported) => {
  if (isSupported) {
    firebase.analytics();
  }
});
// const appCheck = firebase.appCheck();
// appCheck.activate(
//   new firebase.appCheck.ReCaptchaEnterpriseProvider(
//     /* reCAPTCHA Enterprise site key */
//   ),
//   true // Set to true to allow auto-refresh.
// );

export default firebase;
