import React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Stack,
  Alert,
  ProgressBar,
} from 'react-bootstrap';
import { useFormatMessage } from 'hooks';
import { useFilePicker } from 'use-file-picker';

import firebase from '../../../firebase';

// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers';
// import * as yup from 'yup';
import './PopupModal.css';

// const schema = yup.object().shape({
//   email: yup.string().email().required(),
//   password: yup.string().min(6).required(),
// });

function Modal({ setOpenModal }) {
  // const { register, handleSubmit, errors, watch } = useForm({
  //   defaultValues: {},
  //   resolver: yupResolver(schema),
  // });
  const [file, setFile] = React.useState('');
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [variant, setVariant] = React.useState('');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [isUploading, setIsUploading] = React.useState(false);
  const [fileProgress, setFileProgress] = React.useState(0);
  const [
    openFileSelector,
    { filesContent, plainFiles, loading },
  ] = useFilePicker({
    accept: 'image/*',
  });

  React.useEffect(() => {
    if (plainFiles.length > 0) {
      // setFile(filesContent[0]);
      console.log(plainFiles[0]);
      setFile(plainFiles[0]);
    }
  }, [plainFiles]);

  function getRandomFileName() {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
    const random = `${Math.random()}`.substring(2, 8);
    const randomNumber = timestamp + random;
    return randomNumber;
  }

  function handleChange(event) {
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  }

  function handleNameChange(event) {
    setName(event.target.value);
  }
  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      console.log(e.dataTransfer.files);
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleClick = () => {
    setOpenModal(false);
    window.open('https://shop.hargharjhanda.com', '_blank');
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('submitted');

    if (file && !isUploading) {
      console.log('file');
      // firebase.storage().ref().child(`users/${makeid(10)}.jpg`).put(file).then((result)=>{
      //   console.log(result);
      // }).catch((error)=>{
      //   console.log(error);
      // });
      const storageRef = firebase
        .storage()
        .ref(`/tiranga/${getRandomFileName()}.PNG`);
      const uploadTask = storageRef.put(file);
      setIsUploading(true);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          console.log(percent);
          setFileProgress(percent);
        },
        (err) => {
          setVariant('error');
          setIsUploading(false);
          setAlertMessage('Error uploading');
          console.log(err);
        },
        () => {
          // download url
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            firebase
              .firestore()
              .collection('tiranga')
              .add({
                user_name: name,
                user_email: email,
                image_path: downloadURL,
                timestamp: Date.now(),
              })
              .then((result) => {
                setIsUploading(false);
                setVariant('success');
                setAlertMessage(
                  'Your image is uploaded successfully and sent for moderation.'
                );
                setTimeout(() => {
                  setOpenModal(false);
                }, 2000);
                console.log(result);
              })
              .catch((err) => {
                setVariant('error');
                setIsUploading(false);
                setAlertMessage('Error uploading');
                console.log(err);
              });
          });
        }
      );
    }
  };

  return (
    <>
      <div className="modalBackground">
        <div className="modalContainer ">
          <Container>
            <Row>
              <Col>
                <div
                  className="float-right"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <img
                    className="position-absolute img-fluid"
                    src="/images/x-circle.svg"
                    alt="X"
                    style={{ right: '-4%', top: '-3%', height: '20px' }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="">
                <div>
                  <h2 className="title">Selfie with the flag</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="">
                <div className="">
                  <Form onSubmit={handleSubmit} className="pt-3">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextName"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Col sm="10" className=" ">
                        {/* <Form.Label column sm="2">
                        Name
                      </Form.Label>
                     
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          style={{  borderRadius: '10px' }}
                          onChange={handleNameChange}
                          required
                        /> */}
                        <Form.Group
                          className="mb-2"
                          controlId="exampleForm.ControlInput1"
                        >
                          {/* <Form.Label className="text-left mb-0">Name</Form.Label> */}
                          <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                      </Col>
                    </Form.Group>

                    {/* <Form.Group
                      as={Row}
                      className="mb-3 "
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="2">
                        Email
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="email"
                          style={{ width: 'auto', borderRadius: '10px' }}
                          onChange={handleEmailChange}
                          required
                        />
                      </Col>
                    </Form.Group> */}
                    {/* <Form.Group controlId="formFile" className="mb-3">
    <Form.Label>Pick tiranga</Form.Label>
    <Form.Control type="file" accept="image/*" onChange={handleChange} />
  </Form.Group> */}
                    {/* <Stack  gap={2}>
                      
                    </Stack> */}
                    <div
                      className="text-center"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <div
                        className="file-container"
                        style={{
                          // marginLeft: '65px',
                          pointerEvents: file ? 'none' : '',
                          opacity: file ? '0.6' : '1',
                        }}
                        onDrop={handleDrop}
                        onDragOver={handleDrop}
                      >
                        {/* <Stack> */}
                        <div className=" mt-3 text-center">
                          <svg
                            style={{ width: '24px', height: '24px' }}
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z"
                            />
                          </svg>
                        </div>
                        <div
                          className=" mx-2 "
                          style={{
                            fontSize: '13px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <p style={{ paddingTop: '10px' }}>
                            Dropdown Files or
                          </p>
                        </div>

                        <div
                          onClick={() => openFileSelector()}
                          className="bg-light border mx-2 text-center "
                          type="file"
                          style={{
                            fontSize: '13px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <p style={{ paddingTop: '10px' }}>Browse Files</p>
                        </div>
                        {/* </Stack> */}
                      </div>
                    </div>
                    <div className="text-center my-3">
                      <button
                        className="buy-flag"
                        type="submit"
                        style={{
                          opacity: isUploading ? '0.6' : '1',
                        }}
                        disabled={isUploading}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      {file && file.name}
                      {file && (
                        <span
                          onClick={() => {
                            setFile('');
                          }}
                        >
                          {' '}
                          <img
                            className="position-absolute img-fluid"
                            src="/images/x-circle.svg"
                            alt="X"
                            style={{
                              height: '20px',
                              marginLeft: '5px',
                              marginBottom: '3px',
                            }}
                          />
                        </span>
                      )}
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {alertMessage && (
                  <Alert variant={variant}>{alertMessage}</Alert>
                )}
                {isUploading && <ProgressBar animated now={fileProgress} />}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Modal;
