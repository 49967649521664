import React from 'react';
import { useFormatMessage } from 'hooks';
import { firebaseError, uiConfig } from 'utils';
import ErrorMessage from 'components/ErrorMessage';
import paths from 'pages/Router/paths';

function Signup() {
  const textMessage = useFormatMessage('Login.text');

  const setPasswordMessage = useFormatMessage('Login.setPassword');

  const forgotPasswordMessage = useFormatMessage('Login.forgotPassword');

  const invalidPasswordMessage = useFormatMessage('Login.invalidPassword');

  return (
    <>
      {/* <section
        className="section hero is-fullheight is-error-section"
        style={{
          backgroundImage: `url("/images/login_bg.jpg")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      ></section> */}
    </>
  );
}

export default Signup;
