import { React, useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import firebase from '../../firebase';

import Layout from '../../components/Layout';

function Selfi() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [images, setImages] = useState([]);
  const [screenSize, setScreenSize] = useState('');

  useEffect(() => {
    setScreenSize(window.innerWidth);
    const ref = firebase.firestore();

    // Create a query against the collection

    const db = ref.collection('tiranga');

    db
      // .where('moderated', '==', true)

      .orderBy('moderated', 'desc')
      .get()
      .then((snapshot) => {
        const imageList = [];
        snapshot.forEach((value) => {
          imageList.push(value.data().image_path);
        });
        setImages(imageList);
      });
  }, []);
  return (
    <>
      <Layout>
        <Container
          style={{
            justifyContent: 'center',
          }}
        >
          <Row className="about-section align-items-center">
            <Col lg={12} sm={12}>
              <Container className="mt-4">
                <Row style={{ display: 'contents' }}>
                  {(images.length >= 0
                    ? images
                    : t('images', {
                      returnObjects: true,
                    })
                  ).map((item, idx) => (
                    <Col lg={4} md={6} className="hashtagImg">
                      <div
                        style={{
                          backgroundImage: `url(${item})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          padding: 'none',
                          overflowX: 'hidden',
                          height: '25vh',
                          color: '#C1A56E',
                          borderRadius: '5px',
                        }}
                        src={item}
                        alt="img"
                        onClick={() => {
                          //    console.log('helloooooooooo');
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}

export default Selfi;
