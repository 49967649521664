/* eslint-disable */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useFormatMessage } from 'hooks';
import { Wrapper } from '@googlemaps/react-wrapper';
import googlePlacesAutocomplete from 'react-google-places-autocomplete';
import PopupModal from './PopupModal/PopupModal';
import { createCustomEqual } from 'fast-equals';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import flagImage from '../../assets/flag.png';
import newflagImage from '../../assets/new_flag.png';

import firebase from '../../firebase';
import currentLocation from '../../assets/locate.png';
import SearchBox from './SearchBox/SearchBox';
import Section from '../Section';
import './Home.css';
import Certificate from './Certificate/Certificate';
import ShareModal from './Certificate/ShareModal';
const render = (status) => {
  return <h1>{status}</h1>;
};

const Home = () => {
  const ref = React.useRef();
  const [globalMap, setGlobalMap] = React.useState();

  const [clicks, setClicks] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [upins, setUpins] = React.useState([]);
  const [zoom, setZoom] = React.useState(5);
  const [center, setCenter] = React.useState({
    lat: 22.677933,
    lng: 80.461648,
  });
  const [geocoder, setGeocoder] = React.useState(undefined);
  const [isLocating, setIsLocating] = React.useState(false);
  const [show, setShow] = React.useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showFlagCertificate, setShowFlagCertificate] = React.useState(false);
  const [share, setShare] = React.useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);
  const auth = firebase.auth();
  const user = auth.currentUser;

  if (user == null) {
    window.location.href = '/';
  }
  const uname = user.displayName;
  const uImage = user.photoURL;
  const defdatabase = firebase.firestore().collection('pins');

  console.log(user);

  React.useEffect(() => {
    if (window.google != undefined) {
      const geocoder2 = new window.google.maps.Geocoder();
      console.log('geocoder', geocoder2);
      setGeocoder(geocoder2);
      let flagCount=document.getElementById('flagCount');
      if(flagCount){
        flagCount.textContent=count;
      }
    }
  }, [window.google]);

  React.useEffect(() => {
    
    firebase.firestore().collection('pins').get().then((snapshot) => {
      console.log(snapshot.docs);
      const pinvals=[];
      snapshot.docs.forEach((doc) => {
        console.log(doc.data());
        pinvals.push(doc.data());
        
      });
      setClicks(pinvals);
    }
      );
    firebase.firestore().collection('pin_count').doc("XJWs5wYY6IuCyeWudzLI").onSnapshot((snapshot) => {
      console.log(snapshot.data().count);
      const pinvals=[];
      // if(snapshot.docs.length>0){
      //   setCount(snapshot.docs[0].data());
      // }
      setCount(snapshot.data().count);
      
    }
      );
      
  }, []);

  React.useEffect(() => {
    let interval=() => {
      let flagCount = document.getElementById('pinned');
      if (flagCount) {
        flagCount.textContent = count;
        clearTimeout(interval);
      }
     }
   setTimeout(interval, 1000);
  }, [count,clicks]);

 
  const updateDb = (pin) => {
    console.log(pin);
    defdatabase.add(pin).then((docRef) => {
      console.log('Document written with ID: ', docRef.id);
      
    }
    ).catch((error) => {
      console.error('Error adding document: ', error);
    }
    );
  };

  const onClick = (e) => {
    console.log(e);
    let Land = false;
    geocoder
      .geocode({ location: e.latLng })
      .then((result) => {
        console.log(result);
        if (result.results.length > 2) {
          setModalOpen(true);
          // avoid directly mutating state
          setClicks([
            ...clicks,
            {
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
              isNew: true,
              label: uname,
              uImage: uImage,
            },
          ]);
          updateDb({
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
            label: uname,
            uImage: uImage,
          });

          // result.results.forEach((res)=>{
          //   // if(res.formatted_address==="India"){
          //   //   Land=true;

          //   // }
          // });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    //createDocument("flags", e.latLng)

    // addDoc(collection(db, "flags"), e.latLng)
    //   .then((docRef) => {
    //     console.log("Document written with ID: ", docRef);
    //     setTributeId(docRef.id);
    //     setShowMessage(true);
    //   })
    //   .catch((error) => {
    //     console.error("Error adding document: ", error);
    //   });
  };

  const onIdle = (m) => {
    console.log('onIdle', m);
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
    let flagCount = document.getElementById('pinned');
    if (flagCount) {
      flagCount.textContent = count;
    }
  };

  const onMarkerClick = () => {
    console.log('ok');
  };
  return (
    <>
      {/* AIzaSyAjmT7XdscjWduKkOYL7lfhYB48xeWfXrE
    
    AIzaSyA93xR8JywwU_tDrKPfpSjqM5TDDQ8i_FE
    */}
      {/* <div className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{useFormatMessage('Home.home')}</h1>
        </div>
      </div> */}
      {/* {console.log(show, 'Im SHowwwwwwwwwwwww')} */}
      {/* {show ? <Section show={show} onHide={handleClose} /> : null} */}

      <div
        style={{
          display: 'flex',
          height: '100%',
          // cursor: `url(${newflagImage})`,
        }}
      >
        <Wrapper
          apiKey="AIzaSyA93xR8JywwU_tDrKPfpSjqM5TDDQ8i_FE"
          libraries={['places']}
          render={render}
        >
          <Map
            center={center}
            zoom={zoom}
            onClick={onClick}
            setGlobalMapp={setGlobalMap}
            clickCount={clicks}
            setIsLocating={setIsLocating}
            onIdle={onIdle}
            disableDefaultUI={true}
            style={{
              flexGrow: '1',
              height: '90vh',
              position: 'inherit',
            }}
          >
            {clicks.map((latLng, i) => (
              <Marker
                key={i}
                options={{
                  key: i,
                  position: latLng,
                  icon: newflagImage,
                  uImage: latLng.uImage,
                  labell: latLng.label,
                  globalMapp: globalMap,
                }}
                onClick={() => onMarkerClick}
              />
            ))}

            {modalOpen && (
              <PopupModal
                setOpenModal={setModalOpen}
                showCertificate={showFlagCertificate}
                setShowFlagCertificate={setShowFlagCertificate}
                image={uImage}
                name={uname}
              />
            )}

            {showFlagCertificate && (
              <Certificate
                image={uImage}
                name={uname}
                setShowFlagCertificate={setShowFlagCertificate}
                share={share}
                setShare={setShare}
              />
            )}

            {share && <ShareModal setShare={setShare} />}
          </Map>
        </Wrapper>
      </div>

      {/* <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{useFormatMessage('Home.home')}</h1>
        </div>
      </section>

      <section className="section is-main-section">
        {useFormatMessage('Home.content')}

      </section> */}
      {isLocating && (
        <div className="overlay-container">
          <iframe
            src="https://embed.lottiefiles.com/animation/86548"
            style={{ width: '100%', height: '100%' }}
          ></iframe>
        </div>
      )}
    </>
  );
};

export default Home;

const Map = ({
  onClick,
  onIdle,
  children,
  setGlobalMapp,
  setIsLocating,
  style,
  ...options
}) => {
  // [START maps_react_map_component_add_map_hooks]
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (map) {
      let infoWindow = new window.google.maps.InfoWindow();

      const locationButton = document.createElement('div');
      const locationAnimation = document.createElement('iframe');
      locationAnimation.src = 'https://embed.lottiefiles.com/animation/85487';
      locationAnimation.style.height = '45px';
      locationAnimation.style.width = '45px';
      // locationAnimation.style.opacity="0";
      locationAnimation.style.marginBottom = '45px';
      locationButton.style.position = 'absolute';
      locationButton.appendChild(locationAnimation);
      const locationButtonImage = document.createElement('img');
      locationButtonImage.src = './images/target.png';
      locationButtonImage.style.height = '35px';
      locationButtonImage.style.width = '35px';
      locationButtonImage.style.position = 'absolute';
      locationButton.style.height = '50px';
      locationButton.style.width = '50px';
      locationButton.style.marginLeft = '4%';
      locationButtonImage.style.marginLeft = '-35px';
      locationButton.appendChild(locationButtonImage);
      locationButton.classList.add('locationButton');
      locationButtonImage.style.opacity = '1';
      locationAnimation.style.opacity = '0';
      map.setOptions({
        draggableCursor: 'url(/images/location.svg) 20 250 , auto',
      });
      // console.log('map', map);
      locationButton.addEventListener('click', () => {
        if (navigator.geolocation) {
          locationButtonImage.style.opacity = '0';
          locationAnimation.style.opacity = '1';
          setIsLocating(true);
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              infoWindow.setPosition(pos);
              infoWindow.setContent('Location found.');
              infoWindow.open(map);
              map.setCenter(pos);
              smoothZoom(map, 16, map.getZoom());
              locationButtonImage.style.opacity = '1';
              locationAnimation.style.opacity = '0';
              setIsLocating(false);
            },
            () => {
              //  handleLocationError(true, infoWindow, map.getCenter());
              map.setCenter(map.getCenter());
              infoWindow.setContent('Error Getting Location');
              infoWindow.setPosition(map.getCenter());
              infoWindow.open(map);
              setIsLocating(false);
              locationButtonImage.style.opacity = '1';
              locationAnimation.style.opacity = '0';
              // map.setCenter(pos);
            }
          );
        } else {
          // Browser doesn't support Geolocation
          // handleLocationError(false, infoWindow, map.getCenter());
          map.setCenter(map.getCenter());
          infoWindow.setContent('Browser does not support Geolocation');

          infoWindow.setPosition(map.getCenter());
          infoWindow.open(map);
          setIsLocating(false);
          locationButtonImage.style.opacity = '1';
          locationAnimation.style.opacity = '0';
        }
      });
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
        locationButton
      );

      const flagsPinnedElement = document.createElement('div');
      const flagsPinnedElementValue = document.createElement('div');
      flagsPinnedElementValue.classList.add('pinned');
      flagsPinnedElementValue.id = 'pinned';

      flagsPinnedElement.innerText = 'Flags Pinned';
      flagsPinnedElement.appendChild(flagsPinnedElementValue);
      //  flagsPinnedElement.textContent = "Flags Pinned";
      flagsPinnedElement.classList.add('flagsPinned');
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
        flagsPinnedElement
      );
    }
  }, [map]);

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
      // const input = document.getElementById("map_input");
      // const searchBox = new google.maps.places.SearchBox(input);
      setGlobalMapp(map);
    }
  }, [ref, map]);
  // [END maps_react_map_component_add_map_hooks]
  // [START maps_react_map_component_options_hook]
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  // [END maps_react_map_component_options_hook]
  // [START maps_react_map_component_event_hooks]
  React.useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );
      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);
  // [END maps_react_map_component_event_hooks]
  // [START maps_react_map_component_return]
  return React.createElement(
    React.Fragment,
    null,
    React.createElement('div', { ref: ref, style: style }),
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        // set the map prop on the child component
        return React.cloneElement(child, { map });
      }
    })
  );
  // [END maps_react_map_component_return]
};
function smoothZoom(map, max, cnt) {
  if (cnt >= max) {
    return;
  } else {
    let z = window.google.maps.event.addListener(
      map,
      'zoom_changed',
      function (event) {
        window.google.maps.event.removeListener(z);
        smoothZoom(map, max, cnt + 0.25);
      }
    );
    setTimeout(function () {
      map.setZoom(cnt);
    }, 80); // 80ms is what I found to work well on my system -- it might not work well on all systems
  }
}
export { Map };
// [START maps_react_map_marker_component]
const Marker = (options) => {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  React.useEffect(() => {
    if (marker) {
      const contentString =
        '<div>' +
        "<img src='" +
        options.options.uImage +
        "' style='border-radius:50%;width:25px;height:25px; margin-right:5px;'>" +
        options.options.labell +
        '</div>';
      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });

      marker.addListener('mouseover', () => {
        infowindow.open({
          anchor: marker,
          map: options.globalMap,
          shouldFocus: false,
        });
      });

      marker.addListener('mouseout', () => {
        console.log('mouseout');
        infowindow.close();
      });
    }
  }, [marker]);
  return null;
};
export { Marker };
// [END maps_react_map_marker_component]

// [START maps_react_map_searchbox_component]
// const SearchBox = (options) =>{
//   const [searchbox,setSearchbox]=React.useState();
//   const [center, setCenter] = React.useState({
//     lat: 28.787236,
//     lng: 76.918364,
//   });
//   const defaultBounds = {
//     north: center.lat + 0.1,
//     south: center.lat - 0.1,
//     east: center.lng + 0.1,
//     west: center.lng - 0.1,
//   };
//   const option = {
//     bounds: defaultBounds,
//     componentRestrictions: { country: "us" },
//     fields: ["address_components", "geometry", "icon", "name"],
//     strictBounds: false,
//     types: ["establishment"],
//   };
//   React.useEffect(() =>{
//     if(!searchbox){
//       const input=document.getElementById("map_input");
//       const searchBox = new google.maps.places.SearchBox(input);
//       setSearchbox(searchBox);
//     }
//     return ()=>{
//       if(searchbox){

//       }
//     }
//   },[])
//   return null;
// }

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    isLatLngLiteral(a) ||
    a instanceof window.google.maps.LatLng ||
    isLatLngLiteral(b) ||
    b instanceof window.google.maps.LatLng
  ) {
    return new window.google.maps.LatLng(a).equals(
      new window.google.maps.LatLng(b)
    );
  }
  // TODO extend to other types
  // use fast-equals for other objects
  return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
