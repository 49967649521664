import React from 'react';
import './SearchBox.css';

function SearchBox(props) {
  return (
    <span className="SearchBox">
        hello
    </span>
  );
}


export default SearchBox;
