import React from 'react';
import classNames from 'classnames';

import classes from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className="footer" style={{ cursor: 'default' }}>
      <div className="container-fluid">
        <div className={classNames('level', classes.level)}>
          <div className="p-2">
            <div className="level-item">© 2022 Har Ghar Tiranga</div>
          </div>
          {/* <div className={classNames('level-right', classes.levelRight)}>
            <div className="level-item">
              <div className="logo">
                <a href="">
                  <img
                    src=""
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
