import React from 'react';
import Layout from 'components/Layout';
import { Col, Container, Row } from 'react-bootstrap';


function index() {

    const [vendors, setVendors] = React.useState([
        {name: 'JAI PADAM SHREE TRADEX PVT LTD, NEW DELHI', contact:"Jai Singh Sharma- 9818376578"},
        {name: 'GADIA ENTERPRISES, SURAT, GUJARAT', contact:"Arvind Gadia- 9879594885"},
        {name: 'UDDIN ENTERPRISED, KOLKATA', contact:"Saluadin Mandal-9748085513, 8670265010"},
        {name: 'FAIRDEAL CORPORATION, SURAT', contact:"Keyur Govani- 9925000105"},
        {name: 'KUSH LINKERS, AHMEDABAD', contact:"Vijay Chhabria- 9825586342"},
        {name: 'FABRIC FOUNDRY, GURGAON', contact:""},
        {name: 'MENDRO CORPORATION PVT LTD, GAZIABAD', contact:"Arun Gopal-7210273000"},
        {name: 'SIDDHIVINAYAK KNOTS AND PRINTS PVT LTD', contact:"Rakesh Kumar Sarawagi- 0261-2894415"},
        {name: 'GOYAL TRADERS', contact:"Pratik Goyal- 9909159942"},
        {name: 'NIPUN TECHNOWEAVES PVT LTD, SURAT', contact:"Anil Roongta- 9328030520"},
        {name: 'NONA LIFE STYLE, SURAT', contact:"Ankit Rajput- 9205900131"},
    ]);



  return (
    <div className="">
        <Layout>
    <div className="container my-4">
        <div className="row">
            <div className="col text-center">   
                <div className="h4 mt-5" style={{marginTop: '60px'}} >List of Flag Vendors</div>
            </div>
        </div>
       {
        vendors.map((vendor) => {
            return (
              <div className="mx-4">
                 <div className="row text-bold ">
            <div className="col-6 float-right p-3  border rounded">
                <span className="mx-2">
                    {vendor.name}
                </span>
            </div>
            <div className="col-6 float-left p-3  border">
                <span className="mx-2">{vendor.contact}</span>
            </div>
        </div>
              </div>
        
            );
        }
        )
       }
    </div>
        </Layout>
    </div>
  );
}

export default index;