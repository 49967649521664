import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import data from '../../assets/data.json';

function about() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div>
      <Container className="mt-3">
        <Row>
          <Col lg={6} sm={12}>
            <img
              className="flag_img img-fluid"
              src="/images/flag.png"
              alt="flag"
            />
          </Col>
          <Col lg={6} sm={12}>
            <h3 className="flag_title pt-lg-5 mt-3 mb-4 topic-title">
              {t('flag_title')}
            </h3>
            <p className="flag_text  topic-desc">{t('flag_text')}</p>
            <Link className="about_btn" to="/blog">
              <div className="flag_link">
                <div
                  className="flag_btn row mt-5"
                  style={{
                    backgroundColor: '#F97428',
                    height: '57px',
                    width: '200px',
                    borderRadius: '10px',
                  }}
                >
                  <div className="col-8 pt-2">
                    <p className="btn_text mt-2">{t('know_more')}</p>
                  </div>
                  <div className="col-4 pt-2">
                    <img src="/images/arrow_btn.svg" alt="" />
                  </div>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default about;
