import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Layout from '../../components/Layout';

function topic() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Layout>
        <div
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'./images/bg_img1.png'})`,
            backgroundRepeat: 'no-repeat',
            fontFamily: 'sans-serif',
            overflow: 'hidden',
            justifyContent: 'center',
          }}
        >
          <div className="d-flex justify-content-center pt-5">
            <img src={location?.state?.img_url} alt="" />
          </div>
          <div style={{ padding: '5%' }}>
            <p className="d-flex justify-content-center blog-title pb-5">
              {location?.state?.title}
            </p>
            <p className="blog-desc">{location?.state?.desc}</p>
          </div>
          <div className="d-flex justify-content-center pb-5">
            <Link to="/blog">
              <div
                className=" row mt-5 mb-3"
                style={{
                  backgroundColor: '#F97428',
                  height: '57px',
                  width: '130px',
                  borderRadius: '10px',
                  cursor: 'pointer',
                }}
              >
                <div className="col pt-2 d-flex justify-content-center">
                  <p className="btn_text mt-2 ">{t('back_button_text')}</p>
                </div>
                {/* <div className="col-6 pt-2">
                  <img
                    src="/images/arrow_btn.svg"
                    width="40px"
                    height="40px"
                    alt=""
                  />
                </div> */}
              </div>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default topic;
